import { getAction,getByIdAction,postAction,putAction,deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/common/commonDict'


const listDictApi =(params)=>getAction(baseURL+"/dictList",params);

const addDictApi=(params)=>postAction(baseURL+"/dictAdd",params);

const editDictApi=(params)=>putAction(baseURL+"/dictEdit",params);

const deleteDictByIdApi=(params)=>deleteByIdAction(baseURL+'/dictDeleteById',params);

const selectDictByIdApi=(params)=>getByIdAction(baseURL+'/dictSelectById',params);

const dictItemlist =(params)=>getAction(baseURL+"/dictItemlist",params);

const dictItemAdd=(params)=>postAction(baseURL+"/dictItemAdd",params);

const dictItemEdit=(params)=>putAction(baseURL+"/dictItemEdit",params);

const dictItemDeleteById=(params)=>deleteByIdAction(baseURL+'/dictItemDeleteById',params);

const dictItemSelectById=(params)=>getByIdAction(baseURL+'/dictItemSelectById',params);


export{
    dictItemlist,dictItemAdd,dictItemEdit,
    dictItemDeleteById,
    dictItemSelectById,
    selectDictByIdApi,
    deleteDictByIdApi,
    editDictApi,
    addDictApi,
    listDictApi
}
