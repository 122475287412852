<template>
  <div>
    <el-dialog title="添加字典值" :visible.sync="dialogFormVisible" width="30%"  :before-close="handleClose">
      <el-form :model="dictItemFrom" ref="dictItemFrom">
        <el-row>
          <el-col>
            <el-form-item label="对应的值" :label-width="formLabelWidth" prop="value">
              <el-input v-model="dictItemFrom.value"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="文本值" :label-width="formLabelWidth" prop="text">
              <el-input v-model="dictItemFrom.text"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="显示文本" :label-width="formLabelWidth" prop="showText">
              <el-input v-model="dictItemFrom.showText" placeholder="不填默认显示文本值"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="类型" :label-width="formLabelWidth" prop="type">
              <el-input v-model="dictItemFrom.type" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="注解" :label-width="formLabelWidth" prop="description">
              <el-input v-model="dictItemFrom.description"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="排序" :label-width="formLabelWidth" prop="sortOrder">
              <el-input-number v-model="dictItemFrom.sortOrder" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="样式属性" :label-width="formLabelWidth" prop="cssClass">
              <el-input v-model="dictItemFrom.cssClass" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="表格回显样式" :label-width="formLabelWidth" prop="listClass">
              <el-input v-model="dictItemFrom.listClass" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="16">
          <el-col :span="8">
            <el-form-item label="是否启用" :label-width="formLabelWidth" prop="status">
              <el-switch
                v-model="status"
                active-color="#ff4949"
                inactive-color="#13ce66"
                active-text="否"
                inactive-text="是"
                :active-value="2"
                :inactive-value="1"
              ></el-switch>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="是否默认" :label-width="formLabelWidth" prop="isDefault">
              <el-switch
                v-model="isDefault"
                active-color="#ff4949"
                inactive-color="#13ce66"
                active-text="否"
                inactive-text="是"
                :active-value="2"
                :inactive-value="1"
              ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="16">
          <el-col :span="8">
            <el-form-item label="是否禁用" :label-width="formLabelWidth" prop="isDisabled">
              <el-switch
                v-model="dictItemFrom.isDisabled"
                active-color="#ff4949"
                inactive-color="#13ce66"
                active-text="否"
                inactive-text="是"
                :active-value="2"
                :inactive-value="1"
              ></el-switch>
            </el-form-item>
          </el-col>
         
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelForm()">取 消</el-button>
        <el-button type="primary" @click="sumbitDictItem()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  dictItemAdd,
  dictItemSelectById,
  dictItemEdit
} from "@/api/system/dict/dict.js";
export default {
  name: "addDictItem",
  data() {
    return {
      dialogFormVisible: false,
      dictItemFrom: {},
      formLabelWidth: "120px",
      status: 1,
      isDefault: 1,
      dictId: null,
      productSource: null,
      operateType: null
    };
  },
  created() {},
  methods: {
    showFund(dictCode, productSource, type, dictItemId) {
      console.log('==',productSource)
      this.dictCode = dictCode;
      this.dialogFormVisible = true;
      this.productSource = productSource;
      this.operateType = type;
      if (2 == type) {
        this.selectDictItem(dictItemId);
      }
    },
    selectDictItem(dictItemId) {
      dictItemSelectById(dictItemId).then(res => {
        this.dictItemFrom = res.result;
      });
    },
    sumbitDictItem() {
      this.dictItemFrom.dictCode = this.dictCode;
      this.dictItemFrom.status = this.status;
      this.dictItemFrom.isDefault = this.isDefault;
      this.dictItemFrom.productSource = this.productSource;
      this.dictItemFrom.showText =
        this.dictItemFrom.showText === null
          ? this.dictItemFrom.text
          : this.dictItemFrom.showText;
      console.log(this.dictItemFrom.showText);
      if (2 == this.operateType) {
        dictItemEdit(this.dictItemFrom).then(res => {
          this.dialogFormVisible = false;
          this.cancelForm();
          this.$parent.getDictItem(this.dictCode);
        });
      }
      if (1 == this.operateType) {
        dictItemAdd(this.dictItemFrom).then(res => {
          this.dialogFormVisible = false;
          this.cancelForm();
          this.$parent.getDictItem(this.dictCode);
        });
      }
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          this.cancelForm();
          done();
        })
        .catch(_ => {});
    },
    cancelForm() {
      this.loading = false;
      this.dialog = false;
      this.editShow = false;
      this.dialogFormVisible = false;
      this.$refs["dictItemFrom"].resetFields();
      this.$parent.getDictItem();
    }
  }
};
</script>
<style scoped>
</style>